<template>
  <div id="user-profile">
    <b-navbar
      class="header-navbar navbar-shadow align-items-center navbar-brand-center navbar-fixed"
    >
      <slot name="navbar">
        <profile-header />
      </slot>
    </b-navbar>
    <div class="container" style="width: 90%">
      <div class="mt-4 mb-4">
        <b-breadcrumb class="breadcrumb-chevron">
          <b-breadcrumb-item :to="{ name: 'e-book' }">E-book</b-breadcrumb-item>
          <b-breadcrumb-item active>Paling Banyak Dicari</b-breadcrumb-item>
        </b-breadcrumb>
      </div>
      <b-overlay :show="loading">
        <section id="card-images">
          <b-col>
            <b-row>
              <b-col sm="12" md="8" xl="10" class="mb-2">
                <h6 class="mb-1 text-left"><b>Paling Banyak Dicari</b></h6>
                <b-form-group class="blog-search mt-2">
                  <b-input-group class="input-group-merge">
                    <b-form-input
                      id="search-input"
                      v-model="q"
                      placeholder="Search here"
                    />
                    <b-input-group-append class="cursor-pointer" is-text>
                      <feather-icon icon="SearchIcon" />
                    </b-input-group-append>
                  </b-input-group>
                </b-form-group>
              </b-col>
            </b-row>
            <div class="text-center mt-5 mb-5" v-if="data[0] == null">
              <b-img
                :src="require('@/assets/images/elp/empty.png')"
                fluid
                width="200px"
              />
              <h5 class="text-primary">E-book tidak ditemukan!!</h5>
            </div>
            <b-row class="blog-list-wrapper" v-else>
              <b-col
                v-for="(item, i) in currentPageItems"
                :key="i"
                cols="6"
                md="4"
                lg="3"
              >
                <b-card
                  tag="article"
                  no-body
                  class="text-left text-dark"
                  style="height: 350px"
                >
                  <b-container
                    fluid
                    class="bg-light-secondary"
                    style="text-align: center; padding: 10px"
                  >
                    <b-link
                      :to="{ name: 'e-book-detail', params: { id: item.slug } }"
                    >
                      <b-img
                        :src="apiFile + item.photo"
                        :alt="`${item.name}-${item.id}`"
                        class="card-img-top"
                        style="height: 150px; object-fit: cover; width: 100%"
                      />
                    </b-link>
                  </b-container>
                  <b-card-body>
                    <b-card-title style="font-size: 0.75rem">
                      <b-link>
                        <b-badge pill class="mr-75" variant="light-primary">
                          {{ item.category.name }}
                        </b-badge>
                      </b-link>
                    </b-card-title>
                    <b-card-text
                      class="item-content-truncate"
                      v-b-tooltip.hover.top.v-primary="item.name"
                      v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                      title="tooltip on top"
                      variant="primary"
                      style="max-height: 50px; overflow: auto"
                    >
                      {{ item.name }}
                    </b-card-text>
                    <hr />
                    <div
                      class="d-flex justify-content-between align-items-center"
                    >
                      <b-link
                        :to="{
                          name: 'e-book-detail',
                          params: { id: item.slug },
                        }"
                        class="font-weight-bold"
                        v-if="item.price > 0"
                      >
                        {{ formatRupiah(item.price) }}
                      </b-link>
                      <b-link
                        :to="{
                          name: 'e-book-detail',
                          params: { id: item.slug },
                        }"
                        class="font-weight-bold"
                        v-else
                      >
                        Gratis
                      </b-link>
                    </div>
                  </b-card-body>
                </b-card>
              </b-col>
            </b-row>
          </b-col>
        </section>
        <!-- Pagination -->
        <section>
          <b-row>
            <b-col cols="12">
              <b-pagination
                v-model="filters.page"
                :total-rows="totalData"
                :per-page="filters.perPage"
                first-number
                align="center"
                last-number
                prev-class="prev-item"
                next-class="next-item"
              >
                <template #prev-text>
                  <feather-icon icon="ChevronLeftIcon" size="18" />
                </template>
                <template #next-text>
                  <feather-icon icon="ChevronRightIcon" size="18" />
                </template>
              </b-pagination>
            </b-col>
          </b-row>
        </section>
      </b-overlay>
    </div>
    <footer-home />
  </div>
</template>

<script>
import {
  BOverlay,
  BRow,
  BCol,
  BCard,
  BFormInput,
  BCardText,
  BCardTitle,
  BMedia,
  BAvatar,
  BMediaAside,
  BMediaBody,
  BImg,
  BCardBody,
  BCardGroup,
  BLink,
  BBadge,
  BFormGroup,
  BInputGroup,
  BInputGroupAppend,
  BPagination,
  VBTooltip,
  BBreadcrumb,
  BBreadcrumbItem,
  //   BNavbar,
  //   BContainer,
} from "bootstrap-vue";
import { kFormatter } from "@core/utils/filter";
import ContentWithSidebar from "@core/layouts/components/content-with-sidebar/ContentWithSidebar.vue";
import ProfileHeader from "./components/Navbar.vue";
import FooterHome from "./components/Footer.vue";
import Ripple from "vue-ripple-directive";
export default {
  components: {
    BOverlay,
    BRow,
    BCol,
    BCard,
    BFormInput,
    BCardText,
    BCardBody,
    BCardTitle,
    BCardGroup,
    BMedia,
    BAvatar,
    BMediaAside,
    BMediaBody,
    BLink,
    BBadge,
    BFormGroup,
    BInputGroup,
    BInputGroupAppend,
    BImg,
    BPagination,
    BBreadcrumb,
    BBreadcrumbItem,
    ContentWithSidebar,
    // BNavbar,
    // BContainer,
    ProfileHeader,
    FooterHome,
  },
  directives: {
    "b-tooltip": VBTooltip,
    Ripple,
  },
  computed: {
    currentPageItems() {
      let lengthAll = this.data.length;
      this.nbPages = 0;
      for (let i = 0; i < lengthAll; i = i + this.filters.perPage) {
        this.paginated_items[this.nbPages] = this.data.slice(
          i,
          i + this.filters.perPage
        );
        this.nbPages++;
      }

      return this.paginated_items[this.filters.page - 1];
    },
  },
  data() {
    return {
      loading: false,
      filterOptions: null,
      sortBy: null,
      sortByOptions: null,
      totalData: null,
      data: [],
      q: null,
      filters: {
        page: 1,
        perPage: 12,
      },
      paginated_items: {},
      currentPageIndex: 0,
      nbPages: 0,
      blogSidebar: [],
    };
  },
  watch: {
    q: {
      handler(value) {
        if (this.q == "") {
          this.q = null;
          this.getData();
        } else {
          this.getData();
        }
      },
      deep: true,
    },
  },
  methods: {
    tagsColor(tag) {
      if (tag === "SMP") return "light-info";
      if (tag === "SMA") return "info";
      if (tag === "Umum") return "light-danger";
      if (tag === "Mandiri") return "danger";
      if (tag === "UTBK UNBT") return "light-primary";
      if (tag === "Bintara") return "light-warning";
      if (tag === "CPNS") return "warning";
      if (tag === "AKPOL") return "light-success";
      if (tag === "AKMIL") return "success";
      return "primary";
    },
    getData() {
      this.loading = true;
      let params = {
        search: this.q,
        is_free: 0,
      };
      this.$store
        .dispatch("e-book/index", params)
        .then((res) => {
          this.loading = false;
          this.data = res.data.data;
          this.totalData = this.data.length;
        })
        .catch((err) => {
          this.loading = false;
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: `Error `,
              icon: "XIcon",
              variant: "danger",
              text: err.response.data.message,
            },
          });
        });
    },
  },
  created() {
    this.$http.get("/blog/list/data/sidebar").then((res) => {
      this.blogSidebar = res.data;
    });
    this.getData();
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-blog.scss";
.container {
  position: relative;
  text-align: center;
  color: white;
}

/* Bottom left text */
.bottom-left {
  position: absolute;
  bottom: 8px;
  left: 16px;
}

/* Top left text */
.top-left {
  position: absolute;
  top: 8px;
  left: 16px;
}

/* Top right text */
.top-right {
  position: absolute;
  top: 8px;
  right: 16px;
}

/* Bottom right text */
.bottom-right {
  position: absolute;
  bottom: 8px;
  right: 16px;
}

/* Centered text */
.centered1 {
  position: absolute;
  top: 50%;
  left: 40%;
  transform: translate(-50%, -50%);
}
.centered2 {
  position: absolute;
  top: 50%;
  left: 30%;
  transform: translate(-50%, -50%);
}
</style>
